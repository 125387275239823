import { render, staticRenderFns } from "./productHeader.vue?vue&type=template&id=7ebc4576"
import script from "./productHeader.vue?vue&type=script&lang=js"
export * from "./productHeader.vue?vue&type=script&lang=js"
import style0 from "./productHeader.vue?vue&type=style&index=0&id=7ebc4576&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports