<template >
  <comprehensive>
    <div
      class="ddmg-product"
      v-loading="pageLoading"
    >
      <div class="ddmg-product-content">
        <div class="ddmg-product-desc">
          <div class="product-desc-left">
            <div><img
                src="https://ddmallimg.ddmg.com/public/assets/img/index/mr_goods_photo.png"
                alt="大大买钢"
              /></div>
          </div>

          <div class="product-desc-right">
            <div class="desc-right-title">
              <div>
                {{ goodsInfo.productName }} {{ goodsInfo.material }}
                {{ goodsInfo.specifications }} {{ goodsInfo.originPlace }}
              </div>
              <div
                class="pointer"
                @click="share"
              >
                <i class="el-icon-share"></i>分享商品
              </div>
            </div>
            <div class="desc-right-body">
              <div>
                <p>价格</p>
                <p
                  class="money"
                  v-if="goodsInfo.price != ''"
                >
                  {{ goodsInfo.price }}元/吨
                </p>
                <p
                  class="money"
                  v-if="goodsInfo.price == ''"
                >面议</p>
              </div>
              <div>
                <p>仓库</p>
                <p style="color: #333333;display:inline-block;min-width:100px;">{{ goodsInfo.city }}</p>
                <p style="color: #333333;display:inline-block;min-width:100px;">{{ goodsInfo.warehouseName }}</p>
              </div>
              <div>
                <p>服务</p>
                <p style="color: #333333;display:inline-block;min-width:100px;">自提/过户 </p>
                <p style="color: #333333;display:inline-block;min-width:100px;">商家配送</p>
              </div>

              <div>
                <p>开票</p>
                <p style="color: #333333;display:inline-block;min-width:100px;"><img
                    src="../assets/img/kp.png"
                    alt="大大买钢"
                  />一票制</p>
                <p style="color: #333333;display:inline-block;min-width:100px;"><img
                    src="../assets/img/kp.png"
                    alt="大大买钢"
                  />多票制</p>
              </div>
            </div>
            <div class="product-weight">
              <div class="product-weight-one">
                <div class="product-weight-name">
                  <p>规格重量</p>
                </div>
                <p style=" display: flex;justify-content: center;align-items: center;">
                  <!--                  {{ goodsInfo.quantity }}件/{{ goodsInfo.weight }}吨-->
                  <span v-if=" goodsInfo.weight > 0 || goodsInfo.listingSource == 2">库存充足</span>
                  <span v-else>库存不足</span>
                </p>
                <div class="product-weight-sum">
                  <el-input-number
                    v-model="num"
                    controls-position="right"
                    @change="handleChange"
                    :min="1"
                    size="mini"
                    style="margin-right:15px"
                  ></el-input-number>
                  <el-input
                    @change="handleChangeWeight"
                    v-model="numWeight"
                    placeholder=""
                    style="width: 100px"
                    size="mini"
                  ></el-input><span style="margin-left:5px">吨</span>
                </div>
              </div>
              <div class="product-weight-two">
                <div class="product-weight-name">
                  <p>合计</p>
                </div>
                <div class="product-weight-count">
                  共计<span>{{ num }}</span>件 <span>{{ numWeight }}</span>吨 共计:<span>{{ money }}元</span>
                </div>
              </div>
            </div>

            <div class="product-weight-button">
              <div
                class="add product-button"
                @click="setAddGWC"
              >
                加入购物车
              </div>
              <div
                class="buy product-button"
                @click="addOrder(goodsInfo.id)"
              >
                立即购买
              </div>
            </div>
          </div>
        </div>

        <div class="product-table">
          <div class="product-table-head">
            <div><img
                src="../assets/img/dy.png"
                alt="大大买钢"
              /></div>
            <div>商品详情</div>
          </div>
          <div class="product-table-body">
            <div class="product-table-item">
              <div>
                <p>品名：</p>
                <p>{{ goodsInfo.productName }}</p>
              </div>
            </div>
            <div class="product-table-item">
              <div>
                <p>规格：</p>
                <p>{{ goodsInfo.material }}</p>
              </div>
              <div>
                <p>材质：</p>
                <p>{{ goodsInfo.specifications }}</p>
              </div>
              <div>
                <p>钢厂：</p>
                <p>{{ goodsInfo.originPlace }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <textarea
        id="none"
        style="opacity: 0; position: absolute; height: 0.5px; width: 0.5px"
      ></textarea>
    </div>
  </comprehensive>
</template>

<script>

import { mapState, mapActions } from "vuex";
import comprehensive from "./productComprehensive.vue";
export default {
  components: {
    comprehensive,
  },
  name: "product",
  data () {
    return {
      id: "",
      num: 1,
      numWeight: 1,
      money: 0,
      info: {},
    };
  },
  methods: {
    ...mapActions("ddmg/gwc", ["getStockGWC"]),
    ...mapActions("ddmg/gwc", ["getGoods", "getAdjustmentFactor"]),
    async copyJSON (text) {
      //分享商品 - 复制当前链接
      let target = document.getElementById("none");
      target.value = text;
      console.log(target);
      target.select();
      try {
        await document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          message: "分享链接复制成功！",
          type: "success",
        });
      } catch {
        this.$message({
          message: "该浏览器不支持自动复制，请手动复制链接",
          type: "warning",
        });
      }
    },
    //一键下单
    addOrder (id) {
      this.$router.push({ name: "cart", query: { id: id, type: 1, quantity: this.num, weight: this.numWeight } });
    },
    setAddGWC () {
      let isToken = this.isToken();
      if (isToken) {
        let body = {
          quantity: this.num, //类型：Number  必有字段  备注：无
          weight: this.numWeight, //类型：Number  必有字段  备注：无
          goodsId: this.goodsInfo.id, //类型：Number  必有字段  备注：无
        };
        this.getStockGWC(body).then(res => {
          if (res.msgcode === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    share () {
      //分享商品
      let text = window.location.href;
      this.copyJSON(text);
    },
    handleChange () {
      //数量
      // if (this.adjustmentFactor != 0 && this.adjustmentFactor != "") {
      //   this.numWeight = (this.num * this.adjustmentFactor).toFixed(4);
      //   this.money = (this.numWeight * 1 * this.goodsInfo.price * 1).toFixed(2);
      // } else {
      //   this.money = (this.numWeight * 1 * this.goodsInfo.price * 1).toFixed(2);
      // }
      this.money = (this.numWeight * 1 * this.goodsInfo.price * 1).toFixed(2);
    },
    handleChangeWeight () {
      //重量
      this.money = (this.numWeight * 1 * this.goodsInfo.price * 1).toFixed(2);
    },
  },
  computed: {
    ...mapState("ddmg/gwc", {
      goodsInfo: (state) => state.goodsInfo,
      adjustmentFactor: (state) => state.adjustmentFactor,
      pageLoading: (state) => state.pageLoading,
    }),
  },
  created () {
    this.id = this.$route.query.id;
    this.getGoods(this.id).then((res) => {
      // let body = {
      //   product_name: res.data.product_name,
      //   material: res.data.material,
      //   specifications: res.data.specifications,
      //   origin_place: res.data.origin_place,
      // };
      // this.getAdjustmentFactor(body).then((res) => {
      if (res.msgcode == 1) {
        this.money = (this.numWeight * 1 * (this.goodsInfo?.price || 0) * 1).toFixed(2);
      }
      // });
    });
  },
  mounted () { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
  font-size: 16px;
  line-height: 29px;
}
.ddmg-product {
  margin: 20px auto;
  border-top: 1px solid #dddddd;

  .ddmg-product-content {
    width: 1200px;
    margin: 0 auto;

    .ddmg-product-desc {
      display: flex;
      margin-top: 40px;
      align-items: center;

      .product-desc-left {
        width: 360px;
        padding: 20px;
        border: 1px solid #dddddd;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 320px;
          height: 320px;
        }
      }
    }

    .product-desc-right {
      width: calc(100% - 330px);
      margin-left: 30px;
      display: flex;
      flex-direction: column;

      .desc-right-title {
        height: 29px;
        font-size: 21px;
        color: #333333;
        margin-top: 7px;
        display: flex;
        align-items: center;
      }

      .desc-right-title div:nth-child(2) {
        height: 29px;
        color: #3d7dff;
        margin-top: 0px;
        margin-left: 10px;
        margin-right: -4px;
      }

      .desc-right-body div:nth-child(1) {
        margin-top: 0px;
      }

      .desc-right-body {
        height: 180px;
        background: #fafafa;
        margin-top: 20px;
        padding: 20px 20px;

        div {
          display: flex;
          align-items: center;
          margin-top: 20px;
        }

        .money {
          font-size: 20px;
          color: #ff6200;
        }

        div p:nth-child(1) {
          margin-right: 30px;
        }

        div p {
          font-size: 16px;
          color: #888888;
          height: 28px;
          line-height: 28px;
          display: flex;
          align-items: center;
        }

        div p img {
          width: 20px;
        }
      }

      .product-weight {
        display: flex;
        margin-left: 30px;
        flex-direction: column;
        margin-top: 25px;

        .product-weight-one {
          display: flex;
          margin-bottom: 10px;

          .product-weight-sum {
            margin-left: 20px;
          }
        }

        .product-weight-two {
          display: flex;
          font-size: 16px;

          line-height: 28px;

          span {
            font-size: 18px;
            color: #ff6200;
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .product-weight-name {
          font-size: 16px;
          color: #888888;
          line-height: 28px;
          margin-right: 30px;
          width: 80px;
          height: 29px;
        }
      }

      .product-weight-button {
        display: flex;
        margin-left: 140px;
        margin-top: 20px;

        .product-button {
          width: 126px;
          height: 32px;
          cursor: pointer;
          line-height: 30px;
          text-align: center;
          margin-right: 20px;
        }

        .add {
          background: rgb(255, 255, 255);
          border: 1px solid rgb(255, 98, 0);
          color: rgb(255, 98, 0);
        }

        .buy {
          background: #ff6200;
          border: 1px solid #ff6200;
          color: #ffffff;
        }
      }
    }

    .product-table {
      margin-top: 50px;
      border: 1px solid #dddddd;

      .product-table-head {
        background: #fafafa;
        height: 40px;
        line-height: 40px;
        color: #367ef9;
        font-size: 15px;
        padding-left: 22px;
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .product-table-head div img {
        width: 80px;
      }

      .product-table-head div:nth-child(1) {
        position: absolute;
        left: 11px;
        top: -19px;
      }

      .product-table-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .product-table-item {
        width: 100%;

        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
        display: flex;
        font-size: 14px;
      }

      .product-table-item div {
        width: 15%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-right: 15%;
      }
    }
  }
}
</style>
