<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  头部的搜索和导航栏
-->

<template>
  <div :class="{ borders: borderStyle }">
    <div class="header-nav-one">
    <div style="width:470px;height:90px;float: left;margin-top: 10px;">
            <div style="height:100%;width:114px;background-color: #DDDDDD;float: left;">
                <img style="height:90px;vertical-align: baseline;max-width: 114px" src="https://ddmallimg.ddmg.com/public/assets/img/index/shops/hndq.png" alt="大大买钢">
            </div>
            <div style="width:200px;height:90px;float: left;margin-left:15px">
                <p style="width:200px;font-size: 15px;height: 30px;line-height: 30px;font-weight: 600;
                color: #333333;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                    {{goodsInfo.companyName}}</p>
                <p style="font-size: 13px;width:200px;line-height: 30px;color: #333333;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
                    主营：{{goodsInfo.main_products}}</p>
                <p style="font-size: 12px;width:200px;line-height: 30px;color:#3D7DFF;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
                    <span class="im" style="cursor: pointer;" data-id="722" data-product_title="萍钢 线材 HPB300 6.0" data-price="4970.00" data-dec="湖南大强供应链有限公司" data-product_image="https://dhgyl.ddmg.com/Runtime/Upload/image/2021-06-07/60bd948eac560.png">
                    <img style="vertical-align: sub;width: 17px;margin-right: 7px" src="https://ddmallimg.ddmg.com/public/assets/img/index/index/ic_call5.png" alt="大大买钢">联系卖家
                    </span>
                    <a style="color:#3D7DFF;margin-left: 26px" target="_blank"  @click="goDetail(goodsInfo)">进入店铺</a>
                </p>
            </div>
        </div>

      <div class="header-nav-search">
        <div class="nav-search-input">
          <el-input
            placeholder="输入品名、材质等关键词"
            v-model="searchWord"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="input-with-button"
              @click="onSearch()"
            ></el-button>
          </el-input>
        </div>
        <div class="nav-search-keyword" >
          <ul>
            <li v-for="item,index in searchKeyWord" :key="index" @click="onSearch(item)">{{item}}</li>

          </ul>
        </div>
      </div>
      <div class="header-nav-buttons"  @click="clickGwc">
        <div class="shoppingCart">
          <img src="../assets/img/4112.svg" alt="大大买钢"/>
          <p>购物车</p>
        </div>
      </div>
    </div>
    <slider ref="slider"/>
  </div>
</template>

<script>
import util from "@/libs/util.js";
import slider from "./slider.vue";
import storage from "@/utils/storage";
import { mapActions ,mapState} from "vuex";
export default {
  name: "headerNav",
    components: {
        slider,
    },
  data() {
    return {
      borderStyle: false,
      clicklocation: null,
      searchKeyWord:[
        '螺纹钢','盘螺','线材','冷轧板','不锈钢型材','校直线材',
      ],
      searchWord:""
    };
  },
  methods: {
    ...mapActions("ddmg/third", ["postData"]),
      ...mapActions("ddmg/gwc", ["getGoodsCart", "getEditGWC","deleteGwc"]),
    clickGwc(){
        let isToken = this.isToken();
        if(isToken){
		        let userInfo = null;
		      if( storage.getStorage("userInfo")){
		         userInfo  =  storage.getStorage("userInfo");
		      }
            this.getGoodsCart(userInfo.company_id).then((res) => {
                this.$refs.slider.gwc = true;
            });
        }
    },
    goDetail(item){
        this.$router.push({ name: "shop", query: { 'id':item.companyId} });
    },
    handleSelect(val) {
      console.log(val);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop > 34) {
        this.borderStyle = true;
      }
      if (scrollTop < 34) {
        this.borderStyle = false;
      }
    },
    locationBox() {
        this.clicklocation = true;
    },
    locationBoxs() {
      if (this.clicklocation) {
        this.clicklocation = false;
      } else {
        this.clicklocation = true;
      }
    },
    cityBox(val){
      console.log('545',val.target.innerHTML)
    },
    onSearch(val){
          if(val){
           this.$router.push({name:'mall',query:{keyword:val}})
          }else{
            let keyword=this.searchWord?this.searchWord:"";
            this.$router.push({name:'mall',query:{keyword}})
          }
    }
  },
  computed: {
    ...mapState("ddmg/gwc", {
      goodsInfo: (state) => state.goodsInfo,
    }),
  },
  wacth: {},
  mounted() {
    console.log(this.$store.state.ddmg.third);
    window.addEventListener("scroll", this.handleScroll);
    // this.postData().then((res) => {
    //   console.log("543534465", res);
    // });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.header-nav-one {
  display: flex;
  height: 93px;
  line-height: 93px;
  font-family: MicrosoftYaHei;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
  max-width: 1200px;
  margin: 0 auto;
}
.borders {
  width: 100%;
  box-shadow: rgb(94, 94, 94) 0px 20px 20px -20px;
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  background: #ffffff;
  z-index: 5;
}
.reverseimg {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.reverseimgs {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.header-nav-logo {
  line-height: 93px;
  display: flex;
  align-items: center;
}
.header-nav-location {
  display: flex;
  margin-left: 20px;
  margin-right: 76px;
  align-items: center;
}

.locationBox img {
  margin: 0 4px;
}
.locationBox {
  display: flex;
  height: 30px;
  align-items: center;
}
.header-nav-search {
  display: flex;
  flex-direction: column;
}
.nav-search-input {
  width: 495px !important;
  height: 36px !important;
}
.header-nav-one .input-with-select input {
  border-radius: 2px !important;
  border: 1px solid #ef2147ff !important;
  border-right: none !important;
  height: 36px !important;
  line-height: 36px !important;
}

.header-nav-one .el-input-group__append {
  background-color: #ef2147ff !important;
  border-color: #ef2147ff !important;
}
.header-nav-one .input-with-button {
  height: 36px !important;
  color: #ffffff !important;
  background-color: #ef2147ff !important;
  border-color: #ef2147ff !important;
}
.nav-search-keyword ul {
  display: flex;
  list-style: none;
  font-family: MicrosoftYaHei;
  color: #999999;
  font-size: 12px;
}
.nav-search-keyword ul li {
  margin-left: 20px;
}
.header-nav-buttons {
  display: flex;
  align-items: center;
  margin-left: 58px;
}
.toBuy {
  display: flex;
  height: 36px;
  width: 110px;
  align-items: center;
  background-color: #ef2147ff;
  line-height: 36px;
  color: #ffffff;
  justify-content: center;
}
.toBuy img,
.shoppingCart img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.toBuy p,
.shoppingCart p {
  font-size: 14px;
  margin-left: 4px;
  line-height: 36px;
}
.shoppingCart {
  display: flex;
  height: 36px;
  width: 110px;
  align-items: center;
  border: 1px solid #dddddd;
  line-height: 36px;
  color: #666666ff;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 16px;
}
.cityBox button:nth-child(1){
  margin-left: 10px!important;
}
.nav-search-keyword ul li:hover{
  text-decoration:underline
}
// .header-nav-two .el-menu.el-menu--horizontal .el-menu-item {
//   position: relative;
//   overflow: hidden;
// }
</style>
